.fc-today-button, .fc-prev-button, .fc-next-button {
    background-color: #1572e8 !important;
    border-color: #1572e8 !important;
}

.fc-day-sat .fc-h-event, .fc-day-sun .fc-h-event {
    /* background-color: white !important;
    border-color: white !important; */
    -webkit-box-shadow: 0 1px 15px 1px rgb(69 65 78 / 8%);
    box-shadow: 0 1px 15px 1px rgb(69 65 78 / 8%);
}

/* .fc-day-sat .fc-event-main, .fc-day-sun .fc-event-main {
    color: #333333;
} */

.fc-daygrid-event-harness {
    margin-top: 2px;
    margin-bottom: 2px;
}

.fc-event-main {
    padding: 10px;
}

/* .fc-daygrid-day-events, .fc-daygrid-event-harness, .fc-day{
    min-width: max-content !important;
} */

.fc-toolbar-title {
    font-size: 18px !important;
}

.react-datepicker__day--keyboard-selected {
    background-color: #ffffff;
}